<template>
  <div>
    <base-template >
      <LoginTop />
      <div class="set-password-box">
        <div class="item-title">{{ $t('login.setPassWord') }}</div>
        <van-field
          v-model="dataForm.password"
          center
          class="password-input "
          :class="$t('login.setPassWordP').length>45?'password-inputplaceholder':''"
          maxlength="12"
          type="digit"
          :placeholder="$t('login.setPassWordP')"
          @focus="focusFun('forgot_num')" @blur="blurFun('forgot_duration')"
        />
        <van-field
          v-model="dataForm.confirmPassword"
          center
          class="password-input"
          maxlength="12"
          type="digit"
          :placeholder="$t('login.confirmPassWordP')"
        />
        <privacidad ref="privacidad" class="privacidad agree" @changeHandle="changeHandle"  />
        <submit-btn ref="submit-btn" :sub-text="$t('public.confirmBtn')" :submit-flag="submitFlag" @submit="checkType" />
      </div>
    </base-template>
  
  </div>
</template>

<script>
import { getnewtime, getinterval } from '@/utils'
import LoginTop from '@/views/login/components/LoginTop'
import Privacidad from '@/views/register/components/Privacidad'
import toRouter from "@/mixins/toRouter";
import { setPwd, forgetPwd ,loginPoint} from '@/api/axios/index'
import { setToken, setUser } from '@/utils/auth'
export default {
  name:'SetPassWord',
  mixins: [toRouter],
  components: { LoginTop, Privacidad },
  data() {
    return {
      isAgree: true,
      dataForm: {
        mobile: this.$route.query.mobile,
        password: '',
        confirmPassword: ''
      },
    }
  },
  computed: {
    submitFlag() {
      return this.dataForm.password.length > 5 && this.dataForm.confirmPassword.length > 5 && this.isAgree
    }
  },
  watch:{
    'dataForm.password':{
      handler(){
        this.updatecountfun('password','forgot_updatecount')
      }
    }
  },
  methods: {
    // 获取焦点
    focusFun(nametwo) {
      this.start = getnewtime(); // 初始化时间
      this.$store.state.settings.point[nametwo]++
    },

    // 失去焦点 - 计算时长
    blurFun(name) {
      //  计算时长
      this.$store.state.settings.point[name] += getinterval(
        getnewtime(),
        this.start
      );
    
    
    },
    // 次数
    updatecountfun(name,nametwo) {
      // 初始化不要走
      if (!this.dataForm[name]) {
        this.$store.state.settings.point[nametwo]++;
      }
    },
    // 注册还是修改密码
    checkType() {
      if (this.dataForm.password.length < 6) return
      if (this.dataForm.password !== this.dataForm.confirmPassword) {
        this.$toast.fail(this.$t('public.twoPassIsdiff'))
        return
      }
      const forgetPass = this.$route.query.forgetPass
      if (forgetPass) {
        this.forgetPwd()
        return
      }
      this.setPwd()
    },

    // 注册
    async setPwd() {
      const params = this.$route.query
      try {
        await setPwd({ Imimonekele_kulanga: params.customer_id, Infungulo_yankama: this.dataForm.password }) || {}
        this.setInfo(params)
        console.log(params,'params');
        this.$H5Object.registerSuccess(params)
      } catch (err) {
        console.log(err)
      }
    },

    // 忘记密码重置
    async forgetPwd() {
      try {
        const params = this.$route.query
        const { data } = await forgetPwd({ Imimonekele_kulanga: params.customer_id, Infungulo_yankama: this.dataForm.password }) || {}
        // await loginPoint(this.$store.state.settings.point)
        this.$H5Object.loginSuccess(params)
        this.setInfo(params)
        
      } catch (err) {
        console.log(err)
      }
    },

    // 设置用户信息
    setInfo(data) {
      setToken(data?.token)
      setUser(data)
      sessionStorage.setItem('userInfo', JSON.stringify(data || {}))
    },

    // 隐私协议状态更改
    changeHandle(val) {
      this.isAgree = val
    },
   
  }
}
</script>
<style lang="scss" scoped>
:deep(.is-content){
  padding-left: 64px;
  padding-right: 64px;
}

.set-password-box{
  margin-top: 80px;
}

:deep(.password-input){
  width: 100%;
  height: 128px;
  padding: 0;
  margin-bottom: 32px;
  background: #F7F7F7FF;
  border-radius: 32px;
  border: none;
  text-indent: 48px;
  &:last-child{
    margin-bottom: 0;
  }
}
:deep(.password-input){
  margin-bottom: 26px;
}
/deep/ .password-inputplaceholder {
  input::-webkit-input-placeholder{
      width: 570px;
      white-space: inherit ;
      position: relative;
      top: -12px;
      // transform:translateY(-10px)
    }
}
:deep(.btn-box .submit-btn){
  width: auto;
  margin-bottom: 0;
}
:deep(.password-input .van-field__control){
  font-size: 28px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #0B0320;
  padding-left: 26px;
}

:deep(.btn-box){
  // position: fixed;
  // bottom: 42px;
  // left: 0;
  // width: 100%;
  padding: 0;
}
 :deep(.submit-btn.can-submit-btn){
  font-size: 30px;
 }

.item-title{
  font-size: 32px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #1A1A1A;
  line-height: 46px;
  margin-bottom: 32px;
}
.agree{
  margin-top: 70px;
  margin-bottom: 24px;
}
:deep(.van-cell){
  padding: 0;
}
:deep(.main-content){
  background: #fff;
  .child-title{
    display: none;
  }
}
</style>
